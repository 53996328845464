.header {
    display: flex;
    align-items: center;
    height: 100px;
    justify-content: space-between;
    /* Add space between logo and nav */
    padding: 20px 40px 20px 40px;
    background-color: #000000;
    border-bottom: 0.5px solid #000000;
}

.logo-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    /* Space between logo and text */
    font-size: 1.5rem;
    cursor: pointer;
    color: #000000;
}

.logo {
    width: 60px;
    height: 60px;
}

.header-title {
    font-family: url('../assets/fonts/OpenSans-Bold.ttf');
    font-weight: bold;
    color: white;
}


.header-font {
    font-family: url('../assets/fonts/OpenSans-Light.ttf');
    color: white;
}

.nav-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-item {
    margin-right: 60px;
    font-weight: 600;
}

.nav-item:last-child {
    margin-right: 0;
    /* Remove margin for the last item */
}

.nav-link {
    text-decoration: none;
    font-size: 1.0rem;
    color: #ffffff;
    transition: color 0.1s;
}

.nav-link:hover {
    color: #e4ef63;
    /* Highlight color on hover */
}
