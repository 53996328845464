.footer {
    display: flex;
    align-items: center;
    float: left;
    height: 300px;
    justify-content: space-between;
    /* Add space between logo and nav */
    padding: 30px 50px 30px 50px;
    background-color: rgb(244, 244, 244);
}

.footer-font {
    font-family: url('../assets/fonts/OpenSans-Light.ttf');
    color: black;
}

.company-name {
    font-size: 1.5rem;
}

.icon {
    width: 30px;
    height: 30px;
}

.footer-link {
    text-decoration: underline;
    font-size: 1rem;
    color: black;
    transition: color 0.1s;
}

.footer-link:hover {
    color: #636c00;
    /* Highlight color on hover */
}