.body {
    display: flex;
    align-items: center;
    height: auto;
    justify-content: space-between;
    /* Add space between logo and nav */
    padding: 20px 150px 20px 150px;
    background-color: #ffffff;
}

.page-title {
    font-size: 9rem;
}

.updated-information-div {
    display: flex;
    justify-content: center;
    background-color: black;
    height: 200px;
    width: 100%;
    align-items: center;
}

.updated-information-font {
    font-size: 1.5rem;
}

.font-primary-color {
    font-family: url('../../assets/fonts/OpenSans-Bold.ttf');
    color: #BBA53D;
}

.content-subtitle {
    font-size: 2rem;
    margin-top: 50px;
}

.hr {
    margin-top: 50px;
    border-top: 1px solid black;
    border-radius: 50%;
}