.home {
    width: 100%;
}

.top-pannel {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 800px;
    background-color: #000000;
}

.top-title {
    width: 100%;
    font-family: url('../../assets/fonts/OpenSans-Bold.ttf');
    font-weight: bold;
    font-size: 5.5em;
    text-align: center;
    color: white;
}

.top-title-div {
    display: flex;
    width: 100%;
    height: 200px;
    padding: 0px 300px 0px 300px;
    padding-bottom: 350px;
}

.app-images {
    width: 400px;
    height: 800px;
    border: 3px solid rgb(0, 0, 0);
    border-radius: 30px;
    box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.2);
}

.app-images-pannel {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    /* Add space between logo and nav */
    padding: 20px 40px 20px 40px;
    background-color: #ffffff;
}

.middle-pannel {
    display: flex;
    width: 100%;
    height: 800px;
    align-items: center;
    justify-content: center;
    /* Add space between logo and nav */
    padding: 20px 40px 20px 40px;
    background-color: #ffffff;
    border-bottom: 0.5px solid #000000;
}